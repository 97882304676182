var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "goods-return-add-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "添加商品",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "goods-return-add-search-bar"
  }, [_c('van-search', {
    attrs: {
      "placeholder": "请输入商品名称",
      "clearable": ""
    },
    on: {
      "search": _vm.handleSearch
    },
    model: {
      value: _vm.goodsName,
      callback: function callback($$v) {
        _vm.goodsName = $$v;
      },
      expression: "goodsName"
    }
  })], 1), _c('div', {
    staticClass: "app-main-search-container"
  }, [_c('van-list', {
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getGoodses
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.goodses, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "goods-return-add-container__cell",
      on: {
        "click": function click($event) {
          return _vm.handleReturn(item);
        }
      }
    }, [_c('div', {
      staticClass: "goods-return-add-container__cell__image"
    }, [_c('van-image', {
      attrs: {
        "width": "2.26666rem",
        "height": "2.26666rem",
        "src": item.product.image
      }
    })], 1), _c('div', {
      staticClass: "goods-return-add-container__cell__info"
    }, [_c('div', {
      staticClass: "goods-return-add-container__cell__info__name"
    }, [_vm._v(_vm._s(item.product.name))]), _c('div', {
      staticClass: "goods-return-add-container__cell__info__size"
    }, [_c('span', [_vm._v("规格：1*" + _vm._s(item.product.size))])]), _c('div', {
      staticClass: "goods-return-add-container__cell__info__price"
    }, [_vm._v(" 单价："), _c('span', [_vm._v("￥" + _vm._s(item.product.price))])])])]);
  }), 0)], 1), _c('van-dialog', {
    attrs: {
      "title": "加入退货",
      "show-cancel-button": "",
      "confirm-button-text": "确定",
      "confirm-button-color": "#1989fa"
    },
    on: {
      "confirm": _vm.goodsReturn
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.goods ? _c('div', {
    staticClass: "dialog-input-content"
  }, [_c('van-field', {
    attrs: {
      "type": "number",
      "label": "完好的",
      "placeholder": "请输入完好的数量"
    },
    model: {
      value: _vm.goodVolume,
      callback: function callback($$v) {
        _vm.goodVolume = $$v;
      },
      expression: "goodVolume"
    }
  }), _c('van-field', {
    attrs: {
      "type": "number",
      "label": "损坏的",
      "placeholder": "请输入损坏的数量"
    },
    model: {
      value: _vm.badVolume,
      callback: function callback($$v) {
        _vm.badVolume = $$v;
      },
      expression: "badVolume"
    }
  }), _c('van-cell', {
    staticStyle: {
      "color": "#646566"
    },
    attrs: {
      "title": "备注",
      "value": _vm._f("reasonFilter")(_vm.remarkType),
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showReasonView = true;
      }
    }
  })], 1) : _vm._e()]), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.reasons
    },
    on: {
      "select": _vm.selectReason
    },
    model: {
      value: _vm.showReasonView,
      callback: function callback($$v) {
        _vm.showReasonView = $$v;
      },
      expression: "showReasonView"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }