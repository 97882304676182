<template>
  <div class="goods-return-add-container">
    <my-nav-bar
      title="添加商品"
      left-text="返回"
      left-arrow
    />
    <div class="goods-return-add-search-bar">
      <van-search
        v-model="goodsName"
        placeholder="请输入商品名称"
        clearable
        @search="handleSearch"
      />
    </div>
    <div class="app-main-search-container">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="getGoodses"
      >
        <div v-for="(item, index) in goodses" :key="index" class="goods-return-add-container__cell" @click="handleReturn(item)">
          <div class="goods-return-add-container__cell__image">
            <van-image width="2.26666rem" height="2.26666rem" :src="item.product.image" />
          </div>
          <div class="goods-return-add-container__cell__info">
            <div class="goods-return-add-container__cell__info__name">{{ item.product.name }}</div>
            <div class="goods-return-add-container__cell__info__size">
              <span>规格：1*{{ item.product.size }}</span>
            </div>
            <div class="goods-return-add-container__cell__info__price">
              单价：<span>￥{{ item.product.price }}</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <van-dialog
      v-model="show"
      title="加入退货"
      show-cancel-button
      confirm-button-text="确定"
      confirm-button-color="#1989fa"
      @confirm="goodsReturn"
    >
      <div v-if="goods" class="dialog-input-content">
        <van-field
          v-model="goodVolume"
          type="number"
          label="完好的"
          placeholder="请输入完好的数量"
        />
        <van-field
          v-model="badVolume"
          type="number"
          label="损坏的"
          placeholder="请输入损坏的数量"
        />
        <van-cell style="color: #646566" title="备注" :value="remarkType | reasonFilter" is-link center @click="showReasonView = true" />
      </div>
    </van-dialog>
    <van-action-sheet v-model="showReasonView" :actions="reasons" @select="selectReason" />
  </div>
</template>

<script>
import goodsReturnApi from '@/api/goods_return'
import myNavBar from '@/components/my-nav-bar'
const reasonMap = {
  1: '客户取消',
  2: '没上到货',
  3: '客户退货',
  4: '开错单',
  5: '上错货',
  6: '取回收条'
}
export default {
  name: 'GoodsReturnAdd',
  components: { myNavBar },
  filters: {
    reasonFilter(type) {
      return reasonMap[type]
    }
  },
  data() {
    return {
      page: 0,
      limit: 10,
      goodses: [],
      loading: false,
      finished: false,
      goodsName: '',
      goods: null,
      show: false,
      goodVolume: '',
      badVolume: '',
      showReasonView: false,
      remarkType: 3,
      reasons: [
        { type: 1, name: '客户取消' },
        { type: 2, name: '没上到货' },
        { type: 3, name: '客户退货' },
        { type: 4, name: '开错单' },
        { type: 5, name: '上错货' },
        { type: 6, name: '取回收条' }
      ]
    }
  },
  methods: {
    getGoodses() {
      this.page++
      const params = {
        page: this.page,
        limit: this.limit,
        client_id: this.$route.query.client_id,
        goods_name: this.goodsName,
        bill_reject_id: this.$route.query.bill_reject_id
      }
      goodsReturnApi.list(params).then(res => {
        this.goodses = this.goodses.concat(res.data.list)
        this.loading = false
        if (res.data.list.length < this.limit) { this.finished = true }
      })
    },
    handleSearch() {
      this.page = 0
      this.goodses = []
      this.finished = false
      this.getGoodses()
    },
    handleReturn(goods) {
      this.goods = goods
      this.show = true
      this.goodVolume = ''
      this.badVolume = ''
    },
    goodsReturn() {
      this.beginLoad()
      const data = {
        after_order_id: this.$route.query.after_order_id,
        goods_id: this.goods.id,
        good_volume: Number(this.goodVolume),
        bad_volume: Number(this.badVolume),
        remark_type: this.remarkType,
        bill_reject_id: this.$route.query.bill_reject_id,
        is_other: this.$route.query.is_other
      }
      goodsReturnApi.add(data).then(res => {
        this.success(res.msg)
      })
    },
    selectReason(action) {
      this.remarkType = action.type
      this.showReasonView = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .goods-return-add-container {
    padding: 15px 15px 5px 15px;
    &__cell {
      margin-bottom: 10px;
      background-color: #fff;
      padding: 15px;
      display: flex;
      align-items: center;
      &__image {
        display: inline-block;
      }
      &__info {
        display: inline-block;
        padding-left: 10px;
        width: 220px;
        &__name {
          padding-bottom: 4px;
        }
        &__size {
          font-size: 13px;
          padding-bottom: 4px;
          color: #A3A3A3;
        }
        &__volume {
          font-size: 13px;
          padding-bottom: 4px;
          text-align: right;
          color: #A3A3A3;
        }
        &__price, &__amount {
          font-size: 13px;
          span {
            color: #ee0a24;
          }
        }
      }
    }
    .van-swipe-cell {
      &__right {
        .van-button {
          height: inherit;
        }
      }
    }
  }
  .goods-return-add-search-bar {
    z-index: 1;
    position: fixed;
    top: 46px;
    left: 0;
    right: 0;
  }
  .goods-return-add-tips {
    padding-bottom: 10px;
    color: #A3A3A3;
  }
</style>
